import { apiCall } from "../../../Middleware/API/apiRequest";
import { getTokensFromStorage } from "../../../Utils/Auth/AuthToken";
import { RF_PROFILE } from "../../../constants/apiRoutes";

/**
 * list RFProfiles.
 * @returns {Promise} - A promise that resolves with the list of RFProfiles or rejects with an error.
 * @param props
 */
export const listRFProfile = async ({
  accessToken,
  limit,
  offset,
  filter,
  sort,
  moduleName,
  isServiceWorker,
  listController,
  profileGroup,
}) => {
  const data = {
    Filters: filter,
    pageNumber: offset,
    pageSize: limit,
    sort,
  };

  const response = await apiRequest({
    url: `${RF_PROFILE}/Filter?ProfileGroupId=${profileGroup?.SRFProfileGroupId}`,
    method: "POST",
    moduleName,
    isServiceWorker,
    listController,
    body: data,
    accessToken,
  });
  return {
    rfProfiles: response.Payload,
    TotalCount: response?.TotalRecordCount,
  };
};

/**
 * Create RFProfile.
 * @returns {Promise} - A promise that resolves with the creation of test or rejects with an error.
 * @param data
 */
export const createRFProfile = async ({
  data,
  moduleName,
  isServiceWorker,
  listController,
}) => {
  const { accessToken } = getTokensFromStorage();
  const response = await apiRequest({
    url: `${RF_PROFILE}`,
    method: "POST",
    moduleName,
    isServiceWorker,
    listController,
    body: data,
    accessToken,
  });
  return response;
};

/**
 * Update RFProfile.
 * @returns {Promise} - A promise that resolves with the update of test or rejects with an error.
 * @param data
 */
export const updateRFProfile = async ({
  data,
  moduleName,
  isServiceWorker,
  listController,
}) => {
  const { accessToken } = getTokensFromStorage();
  const response = await apiRequest({
    url: `${RF_PROFILE}`,
    method: "PUT",
    moduleName,
    isServiceWorker,
    listController,
    body: data,
    accessToken,
  });
  return response;
};

/**
 * delete RFProfile.
 * @returns {Promise} - A promise that resolves with the update of test or rejects with an error.
 * @param data
 */
export const deleteRFProfile = async ({
  data,
  moduleName,
  isServiceWorker,
  listController,
}) => {
  const { accessToken } = getTokensFromStorage();
  const response = await apiRequest({
    url: `${RF_PROFILE}/${data?.TenantId}?tenant_id=${data?.TenantId}&profileID=${data?.SRFProfileId}`,
    method: "DELETE",
    moduleName,
    isServiceWorker,
    listController,
    body: data,
    accessToken,
  });
  return response;
};

/**
 * get RFProfile.
 * @returns {Promise} - A promise that resolves with the RFProfile or rejects with an error.
 * @param data
 */
export const getRFProfile = async ({
  data,
  moduleName,
  isServiceWorker,
  listController,
}) => {
  const { accessToken } = getTokensFromStorage();
  const response = await apiRequest({
    url: `${RF_PROFILE}/${data?.SRFProfileId}`,
    method: "GET",
    moduleName,
    isServiceWorker,
    listController,
    accessToken,
  });
  return response;
};

/**
 * User Api Call Wrapper Method.
 * @param {object} api - Contains details like url headers method and data.
 * @returns {Promise} - A promise that resolves with the data of user or rejects with an error.
 */
const apiRequest = async (request) => {
  let response;
  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
  };
  request.headers = headers;
  try {
    response = await apiCall(request);
  } catch (e) {
    response = { isSuccess: false, message: e.message };
  }
  return response;
};
